import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-x-2 whitespace-nowrap rounded-2xl text-sm font-medium text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed outline-none focus-visible:ring-2 focus-visible:ring-primary/50 focus-visible:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-primary hover:bg-primary/90",
        outline: "border border-primary hover:bg-primary/10 text-primary",
        secondary: "bg-secondary hover:bg-secondary/90",
        ghost: "bg-transparent hover:bg-primary/10 text-primary",
      },
      size: {
        default: "px-6 py-3",
        xs: "px-2 py-1",
        sm: "px-4 py-2",
        lg: "px-8 py-4",
        icon: "p-3 rounded-full",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

export { Button };
