"use client";

import { Container } from "@/components/container";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { Envelope, List } from "@phosphor-icons/react";
import Image from "next/image";
import Link from "next/link";

const MenuItem = ({ link, children }) => {
  return (
    <Link
      className="relative group tracking-wide hover:text-neutral-900"
      href={link}
    >
      {children}
      <span className="absolute right-0 -bottom-3 left-0 h-[2px] bg-secondary opacity-0 group-hover:-bottom-1 group-hover:opacity-100 transition-all"></span>
    </Link>
  );
};

const MobileMenuItem = ({ link, children }) => {
  return (
    <SheetClose asChild>
      <Link
        className="relative group tracking-wide hover:text-neutral-900"
        href={link}
      >
        {children}
        <span className="absolute right-0 -bottom-3 left-0 h-[2px] bg-secondary opacity-0 group-hover:-bottom-1 group-hover:opacity-100 transition-all"></span>
      </Link>
    </SheetClose>
  );
};

const Header = () => {
  return (
    <header
      className={cn(
        "fixed z-10 flex items-center w-full bg-white shadow h-16 lg:h-20"
      )}
    >
      <div className="w-full">
        <Container>
          <div className="flex items-center gap-x-8">
            <Link href="/">
              <Image
                className="w-auto h-12 lg:h-16 object-left"
                src="/logo.svg"
                width={200}
                height={64}
                alt="logo"
              />
            </Link>
            <div className="flex-grow flex items-center justify-end gap-x-8">
              <div className="flex gap-x-4 max-lg:hidden">
                <MenuItem link="/leistungen">Leistungen</MenuItem>
                <MenuItem link="/angebot">Angebot</MenuItem>
                <MenuItem link="/ueber-uns">Über uns</MenuItem>
                <MenuItem link="/kontakt">Kontakt</MenuItem>
              </div>
              <Button asChild className="max-sm:hidden">
                <Link href="/anfrage">
                  <Envelope size={18} />
                  <span>Terminanfrage</span>
                </Link>
              </Button>

              <Sheet>
                <SheetTrigger asChild>
                  <Button className="lg:hidden" variant="ghost" size="icon">
                    <List size="24" />
                  </Button>
                </SheetTrigger>
                <SheetContent side="top">
                  <div className="flex-grow flex flex-col gap-y-8 items-center justify-center w-full py-8">
                    <div className="flex flex-col items-start gap-y-8 text-xl">
                      <MobileMenuItem link="/leistungen">
                        Leistungen
                      </MobileMenuItem>
                      <MobileMenuItem link="/angebot">Angebot</MobileMenuItem>
                      <MobileMenuItem link="/ueber-uns">
                        Über uns
                      </MobileMenuItem>
                      <MobileMenuItem link="/kontakt">Kontakt</MobileMenuItem>
                    </div>
                    <SheetClose asChild>
                      <Button asChild>
                        <Link href="/anfrage">
                          <Envelope size="16" />
                          <span>Terminanfrage</span>
                        </Link>
                      </Button>
                    </SheetClose>
                  </div>
                </SheetContent>
              </Sheet>
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export { Header };
